import CommonUtil, { getLoginUser } from '@/utils/common-util';
import Log from '@/utils/log';

const INDEX_PAGE_PATH = '/';
const WEB_MAIN_PAGE_PATH = '/w/main/WPFMHP0101M0';
const WEB_REPR_PAGE_PATH = '/w/notice/WPFMHP0601M0';

// 인증이 필요없는 화면
const ANONY_SRN_IDS = ['index', 'UPFMCO0201M0', 'UPFMCO0202M0']; // UPFMCO0201M0, UPFMCO0202M0: 앱 가입 시 약관조회화면
const ANONY_PATH_PREFIXS = ['/sample', '/w'];

const authCheck = (from, to) => {
  // 샘플이랑 w폴더 하위 페이지는 제거
  let needAuth = true;
  ANONY_PATH_PREFIXS.forEach(anonyPathPrefix => {
    if (to.path.startsWith(anonyPathPrefix)) {
      needAuth = false;
    }
  });
  if (!needAuth) {
    return false;
  }
  const srnId = CommonUtil.getScrnId(to.name);
  // 인증이 필요없는 화면
  if (ANONY_SRN_IDS.includes(srnId) || !to.name) {
    return false;
  }
  // 미리보기 화면 인증 패스
  if (srnId.startsWith('UPFMTRPV')) {
    return false;
  }
  // 같은 페이지 hash만 수정된 페이지면 인증 패스
  if (from.name === to.name && from.hash !== to.hash) {
    return false;
  }
  return true;
};
/**
 * Global Navigation Guard
 * @param {Object} context Nuxt Context
 */
export default async function ({ from, route: to, redirect }) {
  Log.log('[미들웨어-NavigationGuard] NavigationGuard START');
  Log.log('[미들웨어-NavigationGuard] from: ', from);
  Log.log('[미들웨어-NavigationGuard] to: ', to);

  // root path -> index page 리다이렉트
  if (!CommonUtil.isMobileApp() && to.path === INDEX_PAGE_PATH) {
    return redirect(301, WEB_MAIN_PAGE_PATH);
  }

  // 운영계에서 Sample 접근 못하도록 막음
  if (CommonUtil.isProd()) {
    if (to.path.startsWith('/sample/')) {
      return redirect(301, WEB_MAIN_PAGE_PATH);
    }
  }

  // 인증 필요 여부(needAuth) 체크
  const needAuth = authCheck(from, to);

  // 인증 체크 로직
  if (needAuth) {
    // 로그인 사용자 정보 가져온다.
    const res = await getLoginUser();
    Log.log('[미들웨어-NavigationGuard] getLoginUser res: ', res);

    if (res == null || res.success === false) {
      Log.log('[미들웨어-NavigationGuard] getLoginUser 로그인 정보 미존재');
      // 로그인 정보 미존재 시
      return redirect(401, WEB_REPR_PAGE_PATH);
    }
  }

  // route의 meta정보로 이전 route를 넘겨준다.
  to.meta.prevRoute = from;

  Log.log('[미들웨어-NavigationGuard] NavigationGuard END');
}

/********************************
 * PFM(통합플랫폼) API 스크립트
 *   - /restapi/cmn/{APIs}
 ********************************/
import { callApi, makeCommonData, responseHandler, errorHandler } from '@/api/pfm';
import CommonUtil, { getLoginUser } from '~/utils/common-util';
import appUtill from '@/src/static/js/monimo/app/bridge/modules/util.js';

const API_PREFIX = '/restapi/cmn';

/**
 * 공통 코드 단건 조회 API
 * @param {String | Array} param 공통코드
 * @returns 공통 코드 단건 조회 결과
 */
export const cdList = async (param, context = window?.$nuxt?.context) => {
  let reqParam = {
    itgC: [],
  };
  if (context.$typeUtil.isString(param)) {
    reqParam = {
      itgC: [
        {
          sysBurC: '001',
          itcEnm: param.toString(),
        },
      ],
    };
  } else {
    for (let i = 0; i < param.length; i++) {
      const codeStr = param[i];
      reqParam.itgC.push({
        sysBurC: '001',
        itcEnm: codeStr,
      });
    }
  }
  return await callApi(`${API_PREFIX}/cdList`, reqParam);
};

/**
 * 공통 메시지 단건 조회 API
 * @param {Object} msgC 메시지내용을 조회할 메시지코드
 * @param {Boolean} progress 로딩 progress 사용 여부
 * @param {Object} context $pfm axios 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 공통 메시지 단건 조회 결과
 */
export const msg = async (msgC, progress = true, context = window?.$nuxt?.context) => {
  return await callApi({ url: `${API_PREFIX}/msg`, progress }, { msgC }, false, false, context);
};

/**
 * 화면 메타 정보 목록을 조회한다.
 * @param {String} srnId 화면ID
 * @returns
 */
export const srnInf = async srnId => {
  return await callApi(`${API_PREFIX}/srnInf`, { srnId });
};

/**
 * 화면 메타 정보 목록을 조회한다.
 * @returns
 */
export const srnList = async () => {
  return await callApi(`${API_PREFIX}/srnList`);
};

/**
 * 화면 메타 정보 목록을 조회한다.
 * @param {String} srnId 화면ID
 * @returns
 */
export const srnListWithId = async srnId => {
  return await callApi(`${API_PREFIX}/srnListWithId`, { srnId });
};

/**
 * 프로필 파일 업로드
 * @param {String} mnmPrflNcknme 모니모프로필별명암호화
 * @param {File|Blob} fileData 파일
 */
export const prflFileUld = async ({ mnmPrflNcknme, fileData }, dontHandlerErrors, context = window?.$nuxt?.context) => {
  try {
    const formData = new FormData();
    formData.append('mnmPrflNcknme', mnmPrflNcknme);
    formData.append(
      'mnmPrflFile',
      fileData,
      `PROFILE_IMAGE_FILE-${(await getLoginUser()).mnmCstMngtNo}-${new Date().getTime()}`,
    );

    context.$log.group(`[callApi] [/cmn/prflFileUld]`);
    context.$log.debug('[REQUEST]');
    context.$log.table(fileData);

    const response = await context.$pfm.$post('/cmn/prflFileUld', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'API-COMMON': JSON.stringify(makeCommonData(null, null, {})), // 공통부를 다 넘길까?
        'SCRN-ID': CommonUtil.getScrnId(), // 화면ID만 넘길까?
      },
    });

    context.$log.debug('[RESPONSE]');
    context.$log.table(response);
    context.$log.groupEnd();

    return responseHandler(response);
  } catch (error) {
    errorHandler(error, dontHandlerErrors);
  }
};

/**
 * 프로필 이미지(base64) 업로드
 * @param {String} mnmPrflNcknme 모니모프로필별명암호화
 * @param {String} mnmPrflImgCn 모니모프로필이미지내용(base64)
 */
export const prflImgUld = async ({ mnmPrflNcknme, mnmPrflImgCn }) => {
  return await callApi(`${API_PREFIX}/prflImgUld`, { mnmPrflNcknme, mnmPrflImgCn });
};

/**
 * 프로필 이미지 조회
 */
export const viewPrflImg = (mnmCstMngtNo, context = window?.$nuxt?.context) => {
  return new Promise((resolve, reject) => {
    context
      .$pfm({
        method: 'post',
        url: '/cmn/viewPrflImg',
        responseType: 'blob',
        data: { mnmCstMngtNo },
      })
      .then(viewPrflImgResponse => {
        if (viewPrflImgResponse?.status === 200) {
          const reader = new FileReader();
          reader.onload = function (e) {
            // ready: 서버로부터 이미지데이터를 받아와서 데이터가 준비되었음을 알려주는 이벤트
            resolve(e.target.result);
          };
          reader.readAsDataURL(viewPrflImgResponse?.data);
        } else {
          reject(new Error(`프로필 조회 오류 - ${viewPrflImgResponse?.status} ${viewPrflImgResponse?.statusText}`));
        }
      })
      .catch(error => {
        let rejectMessage = '프로필 조회 도중 오류가 발생하였습니다.';
        const { message: errorMessage } = error;
        if (error?.response) {
          const { status, statusText, data: errorData } = error?.response;

          if (window?.$nuxt?.context?.env?.ENVR_DV_C === 'L' && status === 504) {
            rejectMessage = '백엔드 서버가 active하지 않은 것 같습니다. 백엔드 서버를 확인해 주세요!';
          } else if (status === 200) {
            rejectMessage = errorMessage || errorData;
          } else {
            rejectMessage = `${status} [${statusText}]` || errorData || errorMessage;
          }
        } else {
          rejectMessage = errorMessage;
        }
        reject(new Error(rejectMessage));
      });
  });
};

/**
 * 모니모약관조회
 * @param {String} srnId 화면ID
 * @returns
 */
export const selListPrv = async srnId => {
  return await callApi(`${API_PREFIX}/prv/selListPrv`, { srnId });
};

/**
 * 모니모약관동의내역 저장
 * @param {Object} 약관동의내역 저장 요청 데이터
 *    {
 *       {String} apiUseUrl API사용URL
 *       {String} mnmCstSvDvC 모니모고객서비스구분코드
 *       {String} mnmPrvId 모니모약관ID
 *       {String} prvAgYn 약관동의여부
 *       {String} wconFomDvC 동의서형태구분코드
 *    }
 * @returns
 */
export const insPrvAg = async param => {
  return await callApi(`${API_PREFIX}/prv/insPrvAg`, { ...param });
};

/**
 * 모니모약관동의내역 저장(Fido)
 * @param {Object} 약관동의내역 저장 요청 데이터 + 모니모인증일련번호
 *    {
 *       {Integer} mnmCtfSn 모니모인증일련번호
 *       {String} apiUseUrl API사용URL
 *       {String} mnmCstSvDvC 모니모고객서비스구분코드
 *       {String} mnmPrvId 모니모약관ID
 *       {String} prvAgYn 약관동의여부
 *       {String} wconFomDvC 동의서형태구분코드
 *    }
 * @returns
 */
export const insPrvAgFido = async param => {
  return await callApi(`${API_PREFIX}/prv/insPrvAgFido`, { ...param });
};

/**
 * 모니모약관동의내역 조회
 * @param {Object} 약관동의내역 조회 요청 데이터
 *    {
 *       {Array[String]} mnmPrvIdArray 모니모약관ID다건(Nullable)
 *    }
 * @returns
 */
export const selListPrvAg = async param => {
  return await callApi(`${API_PREFIX}/prv/selListPrvAg`, { ...param });
};

/**
 * 오픈뱅킹 마케팅 동의 여부 조회
 *
 * @returns {Object}
 *   result {Boolean} 동의여부
 * }
 */
export const selOpbkChoPrvAgYn = async param => {
  return await callApi(`${API_PREFIX}/prv/selOpbkChoPrvAgYn`, param);
};

/**
 * 어카운트인포 약관 동의 여부조회
 *
 * @returns {Object}
 *   result {String}: 약관 동의 여부 ('Y'|'N')
 */
export const selOpbkAcInfPrvAgYn = async (params = {}, progress = true, dontHandlerErrors = false) => {
  return await callApi({ url: `${API_PREFIX}/prv/selOpbkAcInfPrvAgYn`, progress }, params, dontHandlerErrors);
};

/**
 * 주소정제요청
 * @param {Object} 주소정제요청 데이터
 *    {
 *       {String} adrDvC 주소구분코드
 *       {String} inDtlAdr 입력상세주소
 *       {String} inSiguDng 입력시구동
 *    }
 * @returns
 */
export const adrClng = async ({ adrDvC, inDtlAdr, inSiguDng }) => {
  return await callApi(`${API_PREFIX}/adrClng`, { adrDvC, inDtlAdr, inSiguDng });
};

/**
 * 서비스 가능여부 조회 API
 * @returns
 */
export const inqrSrnActvPly = async data => {
  const deviceCode = appUtill.getAppInfo('mblOsDvC');
  if (deviceCode === '01') {
    data.srnId = data.srnId + '_A';
  } else if (deviceCode === '02') {
    data.srnId = data.srnId + '_I';
  }
  return await callApi(`${API_PREFIX}/ply/inqrSrnActvPly`, data);
};

/**
 * 서비스 가능여부 조회 API
 * @returns
 */
export const inqrSrnActvPlyList = async data => {
  const deviceCode = appUtill.getAppInfo('mblOsDvC');
  data.srnIdList.forEach((srnId, i) => {
    if (deviceCode === '01') {
      data.srnIdList[i] = srnId + '_A';
    } else if (deviceCode === '02') {
      data.srnIdList[i] = srnId + '_I';
    }
  });
  return await callApi(`${API_PREFIX}/ply/inqrSrnActvPlyList`, data);
};

/**
 * 서버 현재년월일(년월일) 조회 API
 * @returns {Object}
 *   {
 *      result: 'YYYYMMDD'
 *   }
 */
export const selCurDate = async () => {
  return await callApi(`${API_PREFIX}/selCurDate`);
};

/**
 * 서버 현재시간(년월일시분초) 조회 API
 * @returns {Object}
 *   {
 *      result: 'YYYYMMDDHHmmss'
 *   }
 */
export const selCurDateTime = async (context = window?.$nuxt?.context) => {
  return await callApi(`${API_PREFIX}/selCurDateTime`, context);
};

/**
 * 마케팅약관 노출버전 조회 API
 * @returns {Object}
 *   {
 *      result: 'V1' or 'V2' or 'V3
 *
 *      'V1' : 모마동 + 교류데이터 약관 V1 노출 회원
 *      'V2' : 모마동 + 교류데이터 약관 V2 노출 회원
 *      'V3' : 통합마케팅동의서 노출 회원
 *   }
 */
export const selMrktPrvExpsrVer = async () => {
  return await callApi(`${API_PREFIX}/prv/selMrktPrvExpsrVer`);
};

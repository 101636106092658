/*************************************************
 * 마이데이터(MDP) API 호출을 위한 공통 Helper 스크립트
 *************************************************/
import Modal from '@/utils/modals';
import CommonUtil from '@/utils/common-util';
import StringUtil from '@/utils/string-util';
import StorageUtil from '@/utils/storage-util';
import TypeUtil from '@/utils/type-util';

import { isPfmUrl, toPfmUrl } from '@/utils/url-parser';

// AS-IS 디채 채널 공통부 포맷 재사용
import { makeStdEtxtCrtSysNm } from '@/api/sc-legacy/index';

let txSeq = 0; // 거래순번
const isMonimoBridgeApp = CommonUtil.isMonimoBridgeApp();
/**
 * 마이데이터(MDP) API 호출 공통부(common)를 생성하여 리턴한다.
 * @param {Object} data 요청 데이터
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context dayjs 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 마이데이터(MDP) API 호출 공통부(common)
 */
export const makeCommonData = (data = {}, hasPrivateInfo = false, { $dayjs = window?.$nuxt?.$dayjs }) => {
  const yyyyMMddHHmmssSSS = $dayjs().format('YYYYMMDDHHmmssSSS');
  return {
    ...data?.common,
    // dlngEvnDvC: svId?.substr(10, 1) || 'S',
    scrnId: CommonUtil.getScrnId(),
    stdEtxtCrtDt: yyyyMMddHHmmssSSS.substr(0, 8),
    stdEtxtCrtSysNm: makeStdEtxtCrtSysNm(),
    stdEtxtSn: yyyyMMddHHmmssSSS.substr(8) + StringUtil.padString(++txSeq, 5, '0'),
    stdEtxtPrgDvNo: 0,
    stdEtxtPrgNo: 0,
    indvInfIncYn: hasPrivateInfo ? 'Y' : 'N', // 개인정보포함여부 (For ActionLog) INDV_INF_INC_YN 	: 'Y'/'N'
    usid: StorageUtil?.store?.get('loginUser')?.mnmCstMngtNo ?? 'USERID0',
  };
};

/**
 * 마이데이터(MDP) API 호출 공통부(common)를 포함한 전체 요청데이터 생성
 * @param {Object} data 요청 데이터
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context axios 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 마이데이터(MDP) API 호출 스펙에 맞는 API 요청데이터
 */
export const makeRequestData = (data = {}, hasPrivateInfo = false, context = window?.$nuxt?.context) => {
  // Deep Copy 원본 데이터 가공 방지
  const _data = JSON.parse(JSON.stringify(data));
  _data.common = makeCommonData(data, hasPrivateInfo, context);
  return _data;
};

class CommonResponse {
  constructor(data) {
    this.success = data.success;
    this.message = data.message;
    this.code = data.code;
    this.payload = data.payload;
  }
}

/**
 * 마이데이터(MDP) API 호출
 *
 * @param {String | Object} urlInfo 요청 URL 정보
 * @param {Object} data 요청 데이터
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context axios 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns {CommonResponse} 마이데이터(MDP) API 호출 결과 데이터
 */
export const callMdpApi = async (
  urlInfo,
  data,
  dontHandlerErrors = false,
  hasPrivateInfo = false,
  context = window?.$nuxt?.context,
) => {
  let url = '';
  let callbackScssnData;
  let stackTrace;
  try {
    // Sentry 전송을 위한 호출 직전까지의 스택 트레이스 저장
    // eslint-disable-next-line unicorn/error-message
    const { stack } = new Error();
    stackTrace = stack;

    let progress = true;

    if (TypeUtil.isString(urlInfo)) {
      urlInfo = '/svc-mdp-service' + urlInfo;
      url = urlInfo;
    } else if (TypeUtil.isPlainObject(urlInfo)) {
      urlInfo.url = '/svc-mdp-service' + urlInfo?.url;
      url = urlInfo?.url;
      progress = urlInfo?.progress ?? true;
    }

    if (CommonUtil.isMobileApp()) {
      if (isMonimoBridgeApp) {
        callbackScssnData = await context.$mnmBridge.callBridge(context.$mnmBridge.GET_SCSSN_DATA);
      } else {
        callbackScssnData = (await context.$ongobridge.getScssnData())?.data;
      }
      context.$mdp.setHeader('scssn', callbackScssnData?.scssn);
      context.$mdp.setHeader('x-encst', callbackScssnData?.encst);
    } else if (url.includes('templogin')) {
      context.$mdp.setHeader('scssn', '');
      context.$mdp.setHeader('x-encst', '');
    } else if (!url.includes('templogin')) {
      context.$mdp.setHeader('scssn', context.$storageUtil.sessionStorage.get('scssn'));
      context.$mdp.setHeader('x-encst', context.$storageUtil.sessionStorage.get('encst'));
    }
    context.$mdp.setHeader('dgtl-chnl-srn-id', window?.$nuxt?.$route.path.split('/').pop());
    context.$mdp.setHeader('Client-Type', 'OT');

    context.$log.log(`[공통-MDPAPI][REQUEST][${url}] data: `, data);

    const response = await context.$mdp.$post(url, makeRequestData(data, hasPrivateInfo, context), { progress });

    context.$log.log(`[공통-MDPAPI][RESPONSE][${url}] response: `, response);

    // Sentry 전송을 위한 요청 객체 전달
    return new CommonResponse(responseHandler(response, context, data));
  } catch (error) {
    // Sentry 전송을 위한 호출 직전까지의 스택 트레이스 바인딩
    error.stack = stackTrace;

    return errorHandler(error, dontHandlerErrors, url, context, callbackScssnData);
  }
};

/**
 * 마이데이터(MDP) API 응답 결과 공통 처리 핸들러
 * @param {Object} data
 * @param {Object} context ongobirdge 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @param {Object} request API 요청 객체
 * @returns
 */
export const responseHandler = (data, context = window?.$nuxt?.context, request) => {
  // 성공여부(success) 가 true가 아니라면 오류이다.
  if (!data.success) {
    // 예외를 던져 에러 처리 핸들러가 catch하게 한다.
    const err = new Error(data?.message?.msgKrnCn ?? '처리 도중 오류가 발생하였습니다.');
    err.msgC = data?.message?.msgC;
    err.msgKrnCn = data?.message?.msgKrnCn;

    // Sentry 전송을 위해 에러 객체에 요청, 응답 정보 저장
    err.request = request;
    err.response = { data };

    throw err;
  }

  // 미션달성여부가 'Y' 이면 미션달성 브릿지 호출
  if (data?.missionResult?.misnAchvYn === 'Y') {
    const params = {
      url: isPfmUrl(data?.missionResult?.connUrl)
        ? toPfmUrl(data?.missionResult?.connUrl)
        : data?.missionResult?.connUrl,
      title: data?.missionResult?.misnTitNm,
    };
    if (isMonimoBridgeApp) {
      context.$mnmBridge.callBridge(context.$mnmBridge.OPEN_MISSION_POPUP, params);
    } else {
      context.$ongobridge.openMissionPopup(params);
    }
  }

  return data;
};

/**
 * 마이데이터(MDP) API 에러 처리 핸들러
 * @param {Object} error
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @param {String} url 요청 URL
 * @param context
 * @param callbackScssnData
 */
export const errorHandler = (error, dontHandlerErrors = false, url, context, callbackScssnData) => {
  // 핸들링 여부와 상관없이 Sentry 전송
  const { errorObject, errorType } = context.$sentryUtil.getError(error);
  context.$sentryUtil.captureException(errorObject, errorType);

  // 공통 에러처리를 원하지 않는경우(직접 핸들링 하고자 할 때),
  // 에러를 그대로 return 시킨다.
  if (dontHandlerErrors) return error;

  // 기본 공통 alertMsg
  let alertMsg = '처리 도중 오류가 발생하였습니다.';

  // error 객체의 message를 errorMessage라는 변수명으로 받는다.
  const { request, response, msgC, message: errorMessage } = error;
  context.$log.log('[공통-MDPAPI] errorHandler response: ', response);
  context.$log.log('[공통-MDPAPI] errorHandler msgC: ', msgC);
  context.$log.log('[공통-MDPAPI] errorHandler errorMessage: ', errorMessage);

  let sessionExpActive = false;
  if (context.$appUtil.getAppInfo('mblSappYn') === 'Y') {
    // 세션 만료 관련 인터페이스 적용된 버전 체크
    sessionExpActive = context.$appUtil.activateService({
      ios: '10.1.6',
    });
  } else if (CommonUtil.isDev()) {
    // 개발 로컬에서는 무조건 보임 (테스트)
    sessionExpActive = true;
  }
  context.$log.log('[공통-MDPAPI] errorHandler sessionExpActive: ', sessionExpActive);

  // 오류 메시지 처리
  if (msgC === 'WCHPP2047' && sessionExpActive) {
    // 특정코드, 세션 만료 시 모바일 인터페이스 호출
    if (isMonimoBridgeApp) {
      context.$mnmBridge.callBridge(context.$mnmBridge.FAIL_SESSION);
    } else {
      context.$ongobridge.sessionExpired();
    }
    return;
  } else if (response) {
    // axios 호출 성공하여 response를 받은 경우
    if (request?.status === 0) {
      // 네트워크로의 요청이 아예 못나간 상황 오류 무시. (pause / cancel 등등)
      return;
    }
  }

  // 로컬/개발의 경우 디버깅용 호출 url 정보 표시
  if (CommonUtil.isDev() || CommonUtil.isTest()) {
    let scssn = '';
    if (CommonUtil.isMobileApp()) {
      scssn = callbackScssnData?.scssn;
    } else if (url.includes('templogin')) {
      scssn = '';
    } else if (!url.includes('templogin')) {
      scssn = context.$storageUtil.sessionStorage.get('scssn');
    }
    alertMsg += `<br/>[개발 디버그 정보]<br/>요청 URL: ${url}<br/>scssn: ${scssn}`;
  }

  // 메시지 상자 열기
  const messageDialogData = {
    id: 'mdpErrorMsg',
    contentText: alertMsg?.replace(/[\r\n]+/g, '<br/>'),
    buttons: [
      {
        btnId: 'btnOk',
        btnStyleClass: 'primary',
        btnText: '확인',
        eventName: 'closeServerErrorMsg',
      },
    ],
    closeServerErrorMsg: btnId => {
      window?.$nuxt?.context?.$bus?.$emit('commonDialogState', false);
      // selfClose(string): 함수 호출 이후 이전 webview를 닫을 수 있음
      const params = { target: 'today' };
      if (isMonimoBridgeApp) {
        context.$mnmBridge.callBridge(context.$mnmBridge.OPEN_NATIVE, params);
      } else {
        window?.$nuxt?.$ongobridge?.openNative(params);
      }
    },
  };

  // 메시지 다이얼로그 아이콘 설정  ( I/N 에 대해서는 아이콘 삭제 처리 )
  if (error?.msgC?.startsWith('I') || error?.msgC?.startsWith('N')) {
    messageDialogData.iconName = '';
  }

  // 메시지 다이얼로그 띄움
  Modal.messageDialogData(messageDialogData);
};
